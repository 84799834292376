import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './shared/error/error.component';
import { LoginComponent } from './login/pages/login.component';
import { AuthGuard } from './guards/auth.guard';
import { RolUserGuard } from './guards/rol-user.guard';

const routes: Routes = [
  {
    path: '404',
    component: ErrorComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'dispositivos',
    loadChildren: () => import('./device/device.module').then(m => m.DeviceModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'licencias',
    loadChildren: () => import('./licencia/licencia.module').then(m => m.LicenciaModule),
    canActivate: [AuthGuard, RolUserGuard]
  },
  {
    path: 'organizaciones',
    loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule),
    canActivate: [AuthGuard, RolUserGuard]
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./usuario/usuario.module').then(m => m.UsuarioModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  //imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
