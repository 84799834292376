<div class="login">
    <div class="container-fluid fill">
        <div class="row">
            <div class="col-md-5 mt-md-0 mt-3">
                <img src="assets/svg/logo-xhalthings.svg">
            </div>
        </div>
        <div class="row" style="height: 70%;">
            <div class="col-md-5 mt-md-0 mt-3">
                <div class="form">
                    <h4> Iniciar sesión</h4>
                    <br>
                    <form id="form-Login">
                        <mat-form-field class="login-input">
                            <mat-label>Correo Electrónico*</mat-label>
                            <input type="email" matInput [formControl]="emailFormControl"
                                [errorStateMatcher]="matcherEmail" placeholder="Ejem. nombre@dominio.com">
                            <mat-error
                                *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                Por favor, ingrese un correo electronico válido
                            </mat-error>
                            <mat-error *ngIf="emailFormControl.hasError('required')">
                                El corre electronico es <strong>requerido</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="login-input">
                            <mat-label>Contraseña*</mat-label>
                            <input [type]="hide ? 'password' : 'text'" matInput [formControl]="passwordFormControl"
                                [errorStateMatcher]="matcherPswd" placeholder="******">
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error *ngIf="passwordFormControl.hasError('required')">
                                La contraseña es <strong>requerida</strong>
                            </mat-error>
                        </mat-form-field>
                        <button type="submit" class="boton-secundario" (click)="loginUser()">Iniciar sesión</button>
                        <br>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>