import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor{

  constructor() { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token: string = localStorage.getItem('auth_token');
    let request = req;
    if (token) {
      request = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${ token }`)
                  .set('Content-Type', 'application/json')
      });
    }
    return next.handle(request);
  }
}
