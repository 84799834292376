<div id="navBarLog" *ngIf="isLoggedIn">
    <nav class="navbar sticky-top navbar-expand-lg headNav" >
        <a class="navbar-brand">
            <img src="./../../../assets/svg/logo-xaldigital.svg" class="logoXD">
        </a>
        <button class="navbar-toggler collapsed botonNav" type="button" (click)="toggleState()">
            <mat-icon aria-hidden="false" [ngStyle]="{'color':'#fbe40a'}" aria-label="Example home icon">menu</mat-icon>
            
        </button>
    
        <div class="navbar-collapse" [ngClass]="{ 'collapse': isCollapse }">
            <ul class="navbar-nav mx-auto">
                <li routerLinkActive="active" class="nav-item link-nav" *ngIf="rolUser === 'admin'">
                    <a routerLink="organizaciones/detalle-organizacion">
                        ORGANIZACIONES
                    </a>
                </li>
                <li routerLinkActive="active" class="nav-item link-nav">
                    <a routerLink="dispositivos/listado">
                        DISPOSITIVOS
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item dropdown ">
                    <a class="user" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{nameUser}}
                        <img src="../../../assets/svg/icono-user-amarillo.svg" width="30px">
                    </a>
    
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" routerLink="usuarios/perfil-usuario">Perfil</a>
                        <a class="dropdown-item" role="button" (click)='logout()'>Cerrar Sesion</a>
                    </div>
                </li>
            </ul>
            <button class="boton-primario" disabled>Añadir dispositivo</button>
        </div>
    </nav>
    <nav class="navbar navbar-expand-lg bodyNav">
        <div class="container-fluid text-center text-md-left " style="width: 100%;">
            <div class="row" style=" width: 100%;">
                <div class="col-md-5 mt-md-0 mt-3 d-none d-sm-block" >
                    <h5>Bienvenido {{nameUser}}</h5>
                </div>
                <div class="col-md-4 mb-md-0 mb-3">
                    <h4 class="actualRoute">{{actualRoute | uppercase}} </h4>
                </div>
                <div class="col-md-3 mb-md-0 mb-3">
                    <div *ngIf="rolUser === 'admin'">
                        <ul class="navbar-nav">
                            <li class="nav-item dropdown">
                                <a class="admin dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    Opciones de Administrador
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <a class="dropdown-item" routerLink="usuarios/listado">Usuarios</a>
                                    <a class="dropdown-item" routerLink="licencias/listado">Licencias</a>
                                    <a class="dropdown-item" routerLink="organizaciones/listado">Organizaciones</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    
</div>

<nav class="navbar sticky-top navbar-expand-lg headNav" *ngIf="!isLoggedIn">
    <div class="logoLogin">
        <img src="./../../../assets/svg/logo-xaldigital.svg">
    </div>

    <a routerLink="login" class="registro">
        <strong>REGISTRARSE</strong>
    </a>
</nav>