import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';

import { NavbarComponent } from './navbar/navbar.component';

import { AuthInterceptorService } from './Interceptors/auth-interceptor.service';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorInterceptorService } from './Interceptors/error-interceptor.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { AvisosComponent } from './dialogs/avisos/avisos.component';
import { FooterComponent } from './footer/footer.component';
import { ErrorComponent } from './shared/error/error.component';
import { LoginModule } from './login/login.module';
import { AlertConfirmComponent } from './dialogs/alert-confirm/alert-confirm.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    AvisosComponent,
    FooterComponent,
    ErrorComponent,
    AlertConfirmComponent
  ],
  imports: [
    HttpClientModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatDialogModule,
    FormsModule,
    LoginModule,
    BrowserAnimationsModule,
    NgbModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true}

],
  bootstrap: [AppComponent],
  entryComponents: [AvisosComponent]
})
export class AppModule { }
