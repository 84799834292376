<div class="contenedor">
    <div class="error">
        <img src="../../../assets/images/xal.png" >
        <h1>Ummm...</h1>
        
        <h5>Parece que la página solicitada no se encuentra disponible.<br>
            Intente navegar hacia alguna de estas páginas:
        </h5>

        <button class="boton-secundario" [routerLink]="['/dispositivos/listado']">Dispositivos</button>
        <button class="boton-secundario"[routerLink]="['/login']">Login</button>
    </div>
</div>