import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/services/login.service';
import { UtilsXalThings } from '../_helpers/utils-xalthings.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isLoggedIn:boolean ;

  constructor( private loginSrv: LoginService,
    private xalThings: UtilsXalThings) {
      
    }

  ngOnInit() {
    
    this.xalThings.log$.subscribe(islog =>{
      this.isLoggedIn = islog;
    });
  }

  logout(){
    this.loginSrv.logout();
  }

}