import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserResponse } from '../../usuario/interfaces/user-response-interface';
import { map  } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GetUserService {
  constructor(private http: HttpClient) { }

  getAllUsers(page){
    return this.http.post(environment.getAllUsers,page);
  }
  
  getUserByUUID(uuid){
    return this.http.post(environment.getUserbyId,uuid);
  }

  getUserNameByUUID(uuid){
    return this.http.post(environment.getUserbyId,uuid).pipe(
      map( (data:UserResponse) =>{
        const nombre : string = data.result[0].first_name;
        return nombre;
      }))

  }
  
  getUserLoginOptios(uuid){
    return this.http.post(environment.getLoginOptionsUser,uuid);
  }

  getUserpages(){
    return this.http.get(environment.pagesUser);
  }
}
