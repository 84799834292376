// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // -----LOGIN-----
    //Loguear Usuario
    loginUrl: 'https://xalthings-server01.xaldigitalservices.com/LoginOptions/LoginXalThings',

    //Consultas de opciones de logueo
    getLoginOptionsUser:'https://xalthings-server01.xaldigitalservices.com/LoginOptions/QueryLoginOptionsByUuidXalThings',
    getLoginOptionsAllUser:'https://xalthings-server01.xaldigitalservices.com/LoginOptions/QueryAllLoginOptionsXalThings',

    //Opciones de logueo
    newEmailLogin: 'https://xalthings-server01.xaldigitalservices.com/LoginOptions/NewEmailOfLoginXalThings',

    //Modificaciones de logueo
    updateEmailUser:'https://xalthings-server01.xaldigitalservices.com/LoginOptions/UpdateEmailOfLoginXalThings',

    //Eliminacion de logueo
    deleteEmailUser:'https://xalthings-server01.xaldigitalservices.com/LoginOptions/DeleteLoginOptionsXalThings',

  // -----USUARIO-----
    //Crear usuario
    newUser:'https://xalthings-server01.xaldigitalservices.com/Users/NewUserXalThings',

    //Consultas de usuario
    getUserbyId: 'https://xalthings-server01.xaldigitalservices.com/Users/QueryUserByUuidXalThings',
    getAllUsers:'https://xalthings-server01.xaldigitalservices.com/Users/QueryAllUsersXalThings',

    //Modificaciones de usuario
    updateFirstNameUser:'https://xalthings-server01.xaldigitalservices.com/Users/UpdateFirstNameUserXalThings',
    updateLastNameUser:'https://xalthings-server01.xaldigitalservices.com/Users/UpdateLastNameUserXalThings',
    updatePasswordUser:'https://xalthings-server01.xaldigitalservices.com/Users/UpdatePasswordOfUserXalThings',
    
    //Eliminaciones de usuario
    deleteUser:'https://xalthings-server01.xaldigitalservices.com/Users/DeleteUserXalThings',

    //Paginacion usuario
    pagesUser: 'https://xalthings-server01.xaldigitalservices.com/Users/TestXalThings',
    
  // -----LICENCIA-----
    //Crear licencia
    newLicense:'https://xalthings-server01.xaldigitalservices.com/Licenses/NewLicenseXalthings',

    //Consultas de licencia
    getLicensebyId:'https://xalthings-server01.xaldigitalservices.com/Licenses/QueryByIdlicenseXalthings', 
    getAllLicense:'https://xalthings-server01.xaldigitalservices.com/Licenses/QueryAllLicenseXalthings',

    //Modificaciones de licencia
    updateNameLicense:'https://xalthings-server01.xaldigitalservices.com/Licenses/UpdateNameLicenseXalthings',
    updateDescriptionLicense:'https://xalthings-server01.xaldigitalservices.com/Licenses/UpdateDescriptionLicenseXalthings',
    updatePriceLicense:'https://xalthings-server01.xaldigitalservices.com/Licenses/UpdatePriceLicenseXalthings',
    updateDurationLicense:'https://xalthings-server01.xaldigitalservices.com/Licenses/UpdateDurationLicenseXalthings',
    updatePeriodLicense:'https://xalthings-server01.xaldigitalservices.com/Licenses/UpdatePeriodLicenseXalthings',
    updateQuantityLicense:'https://xalthings-server01.xaldigitalservices.com/UpdateQuantityLicenseXalthings',
    updateEnabledLicense:'https://xalthings-server01.xaldigitalservices.com/Licenses/UpdateEnabledLicenseXalthings',
    updateTypeLicense:'https://xalthings-server01.xaldigitalservices.com/Licenses/UpdateLicenseTypeLicenseXalthings',
    
    //Eliminaciones de licencia
    deleteLicense:'https://xalthings-server01.xaldigitalservices.com/Licenses/DeleteLicenseXalthings',

  
  // -----ORGANIZACION-----
    //Crear Organizacion
    newOrganization: 'https://xalthings-server01.xaldigitalservices.com/Organization/NewOrganizationXalThings',

    //Consultas de organizacion
    getOrganizationbyId: 'https://xalthings-server01.xaldigitalservices.com/Organization/QueryByIdorganizationXalThings',
    getAllOrganizations: 'https://xalthings-server01.xaldigitalservices.com/Organization/QueryAllOrganizationXalThings',

    //Modificaciones de organizacion 
    updateNameOrganization:'https://xalthings-server01.xaldigitalservices.com/Organization/UpdateNameOrganizationXalThings',

    //Eliminar organizacion
    deleteOrganization:'https://xalthings-server01.xaldigitalservices.com/Organization/DeleteOrganizationXalThings',

  // ----- ORGANICACIONES/LICENCIAS
    licenciasorganizaciones : 'https://xalthings-server01.xaldigitalservices.com​/R_Organization_Licenses/QueryOrganizationWithLicenseByIdorganization',
    //Licencias no asociadas
  licenciasnoAsociadas:'https://xalthings-server01.xaldigitalservices.com​/R_Organization_Licenses/QueryOrganizationWithoutLicenseByIdorganization',
   //Asociacion Organizacion Lic
   newRelationOrgLic: 'https://xalthings-server01.xaldigitalservices.com/R_Organization_Licenses/NewRelationOrganizationLicensesXalthings',
  
  // -----DISPOSITIVOS ------
    getDevices:'https://xalthings-server01.xaldigitalservices.com/Things/QueryDeviceByUser',
    addDeviceUser:'https://xalthings-server01.xaldigitalservices.com/Things/InsertUsuario',
  // ----- GRÁFICAS -----
    //Consultar gráfica
    getChart: 'https://xalthings-server01.xaldigitalservices.com/Things/GetData'
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


