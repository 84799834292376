import { LoginService } from '../login/services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { AvisosComponent } from 'src/app/dialogs/avisos/avisos.component';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ShowErrors {

    constructor(private loginService: LoginService,
      private dialog: MatDialog) { }
  
    showError(error) {
      switch (error) {
        case 402://Error de token expiradoss
          this.dialog.open(AvisosComponent,
            {
              width: '350px',
              height:'170px',
              data: { titulo: 'La sesión ha expirado', contenido: 'Vuelva a inicar sesión.'}, 
              panelClass: 'dialogo' 
            });
          this.loginService.logout();
          break;
        case 403: //Error de autenticación
          this.dialog.open(AvisosComponent,
            {
              width: '350px',
              height:'170px',
              data: { titulo: 'Error de autenticacion', contenido: 'Usuario y/o contaseña incorrectos' },
              panelClass: 'dialogo'
            });
          break;
        default:
          console.log('error '+error);
          
      }
    }
  }
  