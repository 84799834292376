import { Component, OnInit } from '@angular/core';
import { UtilsXalThings } from 'src/app/_helpers/utils-xalthings.service';
import { LoginService } from '../login/services/login.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isLoggedIn:boolean ;
  nameUser;
  rolUser:string;
  actualRoute: string;
  isCollapse = true;   // guardamos el valor
  toggleState() { // manejador del evento
      let foo = this.isCollapse;
      this.isCollapse = foo === false ? true : false; 
  }

  constructor( private loginSrv: LoginService,
    private xalThings: UtilsXalThings, private router:Router,
    routeActi:ActivatedRoute) {
      router.events.subscribe(
        (event: any) => {
          if (event instanceof NavigationEnd) {
            let url = router.url;
            if(url.includes('listado')) this.actualRoute = url.replace('listado', '').replace(/\//g, '');
            else this.actualRoute =''
          }
        }
      );
    }

  ngOnInit() {
    
    this.xalThings.log$.subscribe(islog =>{
      this.isLoggedIn = islog;
    });

    this.xalThings.name$.subscribe(userName =>{
      this.nameUser = userName;
    })

    this.xalThings.rol$.subscribe(rolUser=>{
      this.rolUser = rolUser;
    })
    
  }

  logout(){
    this.loginSrv.logout();
  }

}