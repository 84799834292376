<!-- Footer -->
<footer class="page-footer pt-4" *ngIf="isLoggedIn">

  <!-- Footer Links -->
  <div class="container-fluid text-center text-md-left">
    <div class="row justify-content-end">

      <img src="assets/svg/logo-xhalthings.svg" width="250" style="margin-right: 2%;" />

    </div>
    <!-- Grid row -->
    <div class="row footer">

      <!-- Grid column -->
      <div class="col-md-8 mt-md-0 mt-3">
        <p>©2019 Xaldigital S.A. de C.V. Todos los derechos reservados|
          <a>Terminos de servicio|</a>
          <a>Aviso de Privacidad|</a>
          <a>Legal.</a>
        </p>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-4 mb-md-0 mb-3" style="padding-top: 3.5%;">
        <!-- LinkedIn-->
        <li>
          <!-- LinkedIn-->
          <a href="https://mx.linkedin.com/company/xal-digital" class="text-reset">
            <img src="assets/svg/icono-linkedin.svg" width="50" height="25" />
            XalDigital
          </a>
          <!-- Facebook-->
          <a href="https://www.facebook.com/xaldigital" class="text-reset">
            <img src="assets/svg/icono-facebook.svg" width="50" height="25" />
            XalDigital
          </a>
          <!-- Instagram-->
          <a href="https://instagram.com/xaldigital_?utm_medium=copy_link" class="text-reset">
            <img src="assets/svg/icono-instagram.svg" width="50" height="25" />
            XalDigital
          </a>
        </li>
      </div>
      <!-- Grid column -->


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->


</footer>
<!-- Footer -->