
          <h1 mat-dialog-title>{{aviso.titulo}} </h1>
          <div class="mensaje">
            <div mat-dialog-content>
              <p>{{aviso.contenido}}</p>
            </div>
            <div mat-dialog-actions>
              <button mat-button [mat-dialog-close]="true" class="boton-primario">Ok</button>
            </div>
          </div>
  