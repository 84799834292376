import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from '../login/services/login.service';
import { UtilsXalThings } from '../_helpers/utils-xalthings.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private xalthings: UtilsXalThings,
    private router: Router) { }

  canActivate() {
    const userId = localStorage.getItem('uuid');
    if (this.loginService.isLogIn()) {
      this.xalthings.log$.emit(true);
      this.xalthings.name$.emit(localStorage.getItem('userName'));
      if (userId == '21' || userId == '24') this.xalthings.rol$.emit('admin');
      else this.xalthings.rol$.emit('reader');
      return true;
    }
    else {
      this.router.navigate(['login']);
    }
  }

}
