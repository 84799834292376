import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UtilsXalThings } from '../../_helpers/utils-xalthings.service';
import { GetUserService } from '../../usuario/services/get-user.service';
import { UserResponse } from '../../usuario/interfaces/user-response-interface';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  url = environment.loginUrl;
  nombre: string;

  constructor(private http: HttpClient, private router: Router,
    private xalthings: UtilsXalThings, private getUserService: GetUserService) {

  }

  login(formLogin): void {
    this.http.post(environment.loginUrl, formLogin)
      .subscribe(
        (response: any) => {
          localStorage.setItem('auth_token', response.token);
          localStorage.setItem('uuid', response.uuid);
          this.xalthings.log$.emit(true);
          this.setUserName();
          this.router.navigate(['dispositivos/listado']);
        },
        (error) => console.log(error));
  }

  setUserName(): void {
    const userId = localStorage.getItem('uuid');
    this.getUserService.getUserByUUID({ "uuid":  userId})
      .subscribe((respose: UserResponse) => {
        this.xalthings.name$.emit(respose.result[0].first_name);
        if(userId == '21' || userId== '24') this.xalthings.rol$.emit('admin');
        else this.xalthings.rol$.emit('reader');
        localStorage.setItem('userName', respose.result[0].first_name);
      })
  }

  logout(): void {
    localStorage.clear();
    this.xalthings.log$.emit(false);
    this.router.navigate(['/login'])
  }

  public isLogIn(): boolean {
    if (localStorage.getItem('auth_token') != null) {
      return true;
    }
    else {
      return false;
    }
  }

}
