import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UtilsXalThings{
  constructor() {}
  log$ = new EventEmitter<boolean>();
  name$ = new EventEmitter<string>();
  rol$ = new EventEmitter<string>();

  getDate(): any{
    var DateObj = new Date();
    var date =(DateObj.getFullYear() + '-' + ('0' + (DateObj.getMonth() + 1)).slice(-2) + '-' + ('0' + DateObj.getDate()).slice(-2));
    var time = (DateObj.getHours()+':'+DateObj.getMinutes()+':'+DateObj.getSeconds())

    var dateTime = date+' '+time
    return dateTime;
   }

  generarArreglo(paginaActual: number, totalPaginas: number): number[] {
    let arreglo: number[] = [];

    if (totalPaginas >= 5) {
      if (paginaActual < 3) {
        arreglo = [0, 1, 2, 3, 4];
      } else if (paginaActual > totalPaginas - 3) {
        arreglo = [];
        for (let indice = totalPaginas - 5; indice < totalPaginas; indice++) {
          arreglo.push(indice);
        }
      } else {
        for (let indice = paginaActual - 2; indice <= paginaActual + 2; indice++) {
          arreglo.push(indice);
        }
      }
    } else {
      for (let indice = 0; indice < totalPaginas; indice++) {
        arreglo.push(indice);
      }
    }
    return arreglo;
  }

}
