import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) { }

  createUser(formCreateUser) {
    return this.http.post(environment.newUser, formCreateUser);
  }

  deleteUser(idUser) {
    return this.http.delete(environment.deleteUser, idUser);
  }
  
  userIsAdmin():boolean{
    const userId = localStorage.getItem('uuid');
    if(userId==='21' || userId ==='24') return true;
    else return false;
  }

}
