import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroupDirective, NgForm, } from '@angular/forms';
import { LoginService } from '../services/login.service';
import { ErrorStateMatcher } from '@angular/material/core';

export class StateMatcherEmail implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
export class StateMatcherPassword implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService]
})

export class LoginComponent implements OnInit {
  hide = true;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passwordFormControl = new FormControl('', [Validators.required]);

  matcherEmail = new StateMatcherEmail();
  matcherPswd = new StateMatcherPassword();

  constructor(private LoginService: LoginService) {}


  buildUser() {
    return {
      "email": this.emailFormControl.value,
      "password": this.passwordFormControl.value
    }
  }

  loginUser(): void {
    if (this.emailFormControl.valid && this.passwordFormControl.status) {
      this.LoginService.login(this.buildUser());
    } 
  }
  ngOnInit(): void {
  }


}
