import { Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

interface Aviso{
  titulo : string,
  contenido : string
}

@Component({
  selector: 'app-avisos',
  template: `
          <h1 mat-dialog-title>{{aviso.titulo}} </h1>
          <div class="mensaje">
            <div mat-dialog-content>
              <p>{{aviso.contenido}}</p>
            </div>
            <div mat-dialog-actions>
              <button mat-button [mat-dialog-close]="true" class="boton-primario">Ok</button>
            </div>
          </div>
  `,
  styleUrls: ['./avisos.component.css']
})
export class AvisosComponent implements OnInit {
  constructor( public dialogRef: MatDialogRef<AvisosComponent>,
    @Inject(MAT_DIALOG_DATA) public aviso: Aviso ){}

  ngOnInit(): void {
  }

}

